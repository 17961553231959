.productsDetailPage {
  .productDetail {
    display: flex;
    align-items: flex-start;

    .gallery {
      flex: 1 1 50%;
    }

    .productInfo {
      flex: 1 1 50%;

      .productTitle h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 20px;
      }

      .productTitle h5 {
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
      }


      .productPrice {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        color: black;
      }

      .productDescription {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .productActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .wishlistButton {
    flex: 1 1 100%;
    display: block;
    background-color: var(--primaryColor);
    color: white;
    font-weight: 700;
    font-size: 20px;
    width: calc(100% - 2.4rem);
    line-height: 2;
    padding: 1rem;
  }

  .productSection {
    margin: 1.2rem 0;
    position: relative;
    width: calc(100% - 2.4rem);;

    button {
      appearance: none;
      background-color: transparent;
      border-bottom: black 1px solid;
      border-color: black !important;
      width: 100%;
      text-align: left;
      padding-bottom: .4rem;
      background-image: url("../assets/images/add.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: -0.2rem;

      &.active {
        background-image: url("../assets/images/minus.svg");
      }
    }

    .sectionContent {
      line-height: 1.4;
      padding-top: .4rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;

      &.active {
        max-height: fit-content;
      }
    }
  }


  .priceBox {
    margin-bottom: 1.2rem;
    display: flex;
    align-items: baseline;
    align-content: baseline;
    flex-flow: row wrap;
  }

  .priceBox .retail_price_label {
    font-size: 20px;
    text-decoration: line-through;
    display: block;
    width: 100%;
    opacity: 0.5;
  }

    .description {
      h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin: 10px 0;
        font-size: 1.6em;
      }

      h1 {
        font-size: 1.6em;
        font-weight: 800;
      }

      h2 {
        font-size: 1.4em;
        font-weight: 700;
      }

      h3 {
        font-size: 1.2em;
        font-weight: 600;
      }

      h4 {
        font-size: 1.1em;
        font-weight: 500;
      }

      h5 {
        font-size: 1em;
        font-weight: 500;
      }

      h6 {
        font-size: 0.9em;
        font-weight: 400;
      }

      p {
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
        line-height: 150%;
      }

      ul, ol {
        margin: 24px;
        margin-top: 0;
        padding-left: 20px;
        list-style-position: outside;
        line-height: 25px;
      }

      ul {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      b, strong {
        font-weight: 600;
      }

      i, em {
        font-style: italic;
        color: inherit;
      }

      small {
        font-size: 0.8em;
        line-height: 1.2;
      }

      sub {
        font-size: 0.85em;
        line-height: 1;
        vertical-align: baseline;
      }

      a {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: rgba(0, 0, 0, 0.7);
        }
      }

      hr {
        border: none;
        height: 1px;
        background-color: rgba(128, 128, 128, 0.5);
        margin: 20px 0;
      }

      table {
        margin-top: 20px;
        border-collapse: collapse;
        overflow: auto;
        width: 100%;
      }

      tr {
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        line-height: 20px;
      }

      td {
        padding: 10px;
        max-width: 256px;
      }

      th, td h1 {
        font-size: 16px;
        font-weight: 600;
        border: none;
        padding: 16px;
        text-align: left;
      }
    }

  .price_and_unit_price {
    display: inline;
  }

  .unit-price {
    margin-left: 0.5rem;
    opacity: 0.5;
  }

  .vat {
    margin-left: 0.5rem;
    opacity: 0.5;
    display: inline;
    font-size: 14px;
    font-weight: 400;
  }

  .crossSelling {
    .title {
      font-size: 20px;
      font-weight: 700;
    }

    padding-left: 1.2rem;
  }

  .fitment-item {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
  }
}

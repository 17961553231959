.product {
  cursor: pointer;
  margin: 10px;
  background: #fff;
  border-radius: 0;
  color: #000;
  line-height: 14px;
  list-style: none;
  height: 282px;
  width: 220px;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  font-size: 15px;
  position: relative;

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    flex: 1 1 auto;
    width: 100%;
    line-height: 2.1;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      line-height: 2.1;
      content: "";
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }


    &.sell:before {
      background-image: url("../assets/images/sell_24dp_E8EAED_FILL1_wght0_GRAD0_opsz24.svg");
    }

    &.mounting:before {
      background-image: url("../assets/images/build_24dp_E8EAED_FILL1_wght0_GRAD0_opsz24.svg");
    }
  }

  .productDetails {
    padding: 10px;
    height: 100px;
    white-space: nowrap;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: 20px;

    .productTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      white-space: break-spaces;
    }

    .series {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      white-space: break-spaces;
    }

    .price {
      position: absolute;
      bottom: 10px;
      left: 10px;

      .retail_price_label {
        color: #000;
        text-decoration: line-through;
        float: left;
        margin-right: 0.4rem;
      }

      .price_label {
        font-weight: 700;
        float: left;
        margin-right: 0.4rem;
      }

      .unit-price {
        float: left;
        opacity: 0.6;
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .wishlist {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .imageContainer {
    transition: opacity .25s ease-in-out;
    position: relative;
    width: 100%;
    height: 150px;
    opacity: 1;

    img {
      transition: opacity .25s ease-in-out;
      opacity: 1;
      height: 150px;
      max-height: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      object-fit: cover;
      display: block;
    }
  }

  &.ph {
    height: 0;
    opacity: 0;
  }
}

.page.startPage {

  &.list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;


    .category {
      color: rgba(0, 0, 0, 1);
      display: block;
      margin: 10px;
      padding: 12px;
      position: relative;
      border: solid 1px;
      flex: 1 1 250px;

      .subTitle {
        display: none;
      }

      > span {
        display: flex;
        width: 100%;
        justify-content: space-between !important;
        align-items: center;
      }

      > span > span {
        padding: 0.3rem;
        color: unset;
      }

      + a {
        font-size: 14px;
      }
    }
  }

  > button {
    position: absolute;
    bottom: 3rem;
    right: 3rem;
  }

  @media (orientation: landscape) {
    &.elements-2 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;
      }
    }
    &.elements-3 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;

        &:last-child {
          grid-column: 2/span 2;
        }
      }
    }


    &.elements-4 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;
      }
    }

    &.elements-5 > div {
      grid-template-columns: repeat(auto-fill, 16.5%);

      .category {
        grid-column: span 2;

        &:nth-child(4) {
          grid-column: 2/span 2;
        }
      }
    }

    &.elements-6 > div {
      grid-template-columns: repeat(auto-fill, 16.5%);

      .category {
        grid-column: span 2;
      }
    }

    &.elements-7 > div {
      grid-template-columns: repeat(auto-fill, 6.25%);

      .category {
        grid-column: span 4;

        &:nth-child(5) {
          grid-column: 3/span 4;
        }
      }
    }

    &.elements-8 > div {
      grid-template-columns: repeat(auto-fill, 6.25%);

      .category {
        grid-column: span 4;
      }
    }
  }

  @media (orientation: portrait) {
    &.elements-1 > div {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    &.elements-2 > div {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    &.elements-3 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;

        &:last-child {
          grid-column: 2/span 2;
        }
      }
    }

    &.elements-5 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;

        &:nth-child(5) {
          grid-column: 2/span 2;
        }
      }
    }

    &.elements-7 > div {
      grid-template-columns: repeat(auto-fill, 25%);

      .category {
        grid-column: span 2;

        &:nth-child(7) {
          grid-column: 2/span 2;
        }
      }
    }

    > div {
      grid-template-columns: repeat(auto-fill, 50%);
    }
  }

  > div {
    position: absolute;
    padding: 12px;
    overflow: hidden;
    display: grid;
    width: calc(100% - 25px);
    height: calc(100% - 128px);
    align-content: stretch;
    align-items: stretch;
    justify-content: space-evenly;

    > div {
      width: calc(100% - 25px);
      height: calc(100% - 90px);
      align-content: stretch;
      align-items: stretch;

    }

    .category {
      margin: 12px;
      justify-content: center;
      align-content: stretch;
      align-items: stretch;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-flow: row wrap;
      color: black;

      span {
        width: 100%;
        align-content: center;
        align-items: center;
        background-color: #fff;
      }

      > span {
        position: relative;
        display: flex;
        flex-flow: row wrap;

        &:first-child {
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;
          box-shadow: inset 0 0 40px 0 rgba(0, 0, 0, 0.6);
          height: 70%;
        }

        &:nth-child(2) {
          font-size: 18px;
          padding-top: 20px;
          align-content: baseline;
          text-align: center;
          height: 30%;


          span {
            margin-top: 6px;
            line-height: 1.2;
            font-size: 15px;
            font-weight: 400;

            &.amount {
              position: absolute;
              top: 0;
              right: 0;
              width: auto;
              font-size: 13px;
              font-weight: 700;
              color: black;
            }

            &.subTitle {
              margin-top: 6px;
              line-height: 1.2;
              font-size: 15px;
              font-weight: 400;
              justify-content: space-evenly;
            }
          }
        }
      }

      @media screen and (orientation: landscape) {
        grid-column: span 2;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1080px) {
    .category-modul {
      font-size: clamp(1rem, 1.5vw, 2rem);
    }

    .more-category {
      font-size: clamp(2rem, 2.5vw, 3rem);
    }
  }

  @media (min-width: 1142px) and (max-width: 1444px) {
    .category-modul {
      font-size: clamp(0.8rem, 1vw, 1.5rem)!important;
    }

    .more-category {
      font-size: clamp(2rem, 1.5vw, 2rem)!important;
    }
  }

  @media (min-width: 760px) and (max-width: 1141px) {
    .category-modul {
      font-size: clamp(0.8rem, 1vw, 1.5rem)!important;
    }

    .more-category {
      font-size: clamp(1.5rem, 1.5vw, 2rem)!important;
    }
  }

  .sale-banner {
    background-image: var(--carImage);
    background-size: cover;
    background-position: center;
  }
}

.page {
  .elements-1 {
  }

  .elements-2 {
  }

  .elements-3 {
  }

  .elements-4 {
  }

  .elements-5 {
  }

  .elements-6 {
  }

  .elements-7 {
  }

  .elements-8 {
  }
}



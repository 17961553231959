.CarSelector {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  background: #fff;
  transform: translateY(-100%);

  &.closing {
    animation: slideOut 0.5s ease-in-out forwards;
  }

  &.open {
    animation: slideIn 0.5s ease-in-out forwards;
  }

  .carBox {
    position: relative;
    margin: auto;
    display: flex;
    width: 90%;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 90%;

    > div {
      position: absolute;
      top: 20%;
      transform: translateY(-10%);
      max-height: 90%;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      overflow-y: auto;
    }
  }

  button.close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    display: inline-block;
    padding: 20px;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: 24px;
    transition: all .15s ease-in-out;
    background-image: url("../assets/images/ic_close_black_24px.svg");
    background-color: white;
  }

  .carFilterItem {
    display: block;
    flex-direction: row;
    margin: 18px 12px;
    cursor: pointer;
    background-color: #fff;
    text-align: start;
    min-width: 200px;

    &:last-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;

    }

    &.ph {
      height: 0;
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 0;
    }

    img {
      height: auto;
      background-repeat: no-repeat;
      display: block;
      width: 100%;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }

}

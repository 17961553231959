.SearchBar {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  top: 68px;
  z-index: 90;

  > input {
    font-weight: 100;
    background-color: #fff;
    color: #000;
    width: calc(100% - 30px);
    height: 42px;
    padding: 0 15px;
    box-shadow: inset 0 0 0 1px #e0e0e0;
    list-style: none;
    border: 0 solid #e0e0e0;
    text-align: right;
    border-radius: 0;
    font-size: 15px;
  }

  > button {
    height: 42px;
    width: 42px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--primaryColor);
  }
}

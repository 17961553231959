.Filter {
  display: inline-flex;
  position: fixed;
  top: 66px;
  z-index: 13;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 0 12px;
  background-color: #FFFFFF;

  .amount {
    padding: 12px 2px;
    color: #000000;
  }

  button.filterPill {
    padding: 12px 2px;
    color: #000000;
    background-color: white;
    cursor: pointer;
    font-family: 'sofi-100', Arial, Helvetica, sans-serif !important;

    &:before {
      content: " X ";
      margin-left: 5px;
    }
  }

  div.filterPill {
    padding: 12px 2px;
    color: #000000;

    &:after {
      padding: 0 0 0 6px;
      content: "/";
    }

    &.isLast:after {
      content: unset;
    }
  }
}

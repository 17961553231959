.slCaptcha {
  margin-top: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex: 0 0 50px;
  justify-content: space-between;

  .captchaImage {
    flex: 0 0 96px;
    img {
      width: 96px;
      border: 2px solid rgba(0, 0, 0, 0.15);
    }
  }

  .captchaInput {
    width: 100%;
    flex: 0 0 calc(100% - 120px) !important;
    border: 0 solid transparent;
  }
}
.Bookmarks {
  position: fixed;
  overflow: auto;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  transform: translateY(-100%);

  &.closing {
    animation: slideOut 0.5s ease-in-out forwards;
  }

  &.open {
    animation: slideIn 0.5s ease-in-out forwards;
  }

  .receiverEmail {
    text-align: center
  }

  .hpm-googleReCaptchaNote {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
  }

  .textField {
    padding-bottom: 8px;

    > input {
      font-weight: 100;
      color: #000;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: calc(100% - 30px);
      height: 42px;
      padding: 0 15px;
      box-shadow: inset 0 0 0 1px #e0e0e0;
      list-style: none;
      border: 0 solid #e0e0e0;
      border-radius: 0;
      font-size: 15px;
      text-align: left;
      background-color: #f0f0f0;

      &.error {
        box-shadow: inset 0 0 0 1px tomato;
      }

      + label {
        display: none;
      }
    }
  }

  > div {
    margin: auto;
    display: flex;
    width: 70%;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100%;
    flex-flow: column;

    > div,
    > form {
      max-width: 580px;
      width: 100%;
      text-align: center;
    }

    > form {
      > div {
        padding-top: 12px;
        padding-bottom: 20px;
        text-align: left;
      }

      button {
        margin: 10px;
        display: inline-block;
        box-sizing: border-box;
        padding: 0 10px 0 30px;

        > span {
          &.send {
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            background-image: url("../assets/images/send_white.svg");
            background-size: 24px;
            transition: all .15s ease-in-out;
            padding: 6px 12px 6px 24px;
            position: relative;
            right: 24px;
            display: initial;

            + span {
              border: 0;
              border-radius: 0;
              transition: all .15s ease-in-out;
              font-weight: 100;
              font-size: 13px;
              line-height: 37px;
              color: #fff;
              text-decoration: none;
              text-transform: uppercase !important;
              letter-spacing: .5px !important;
              -webkit-appearance: none;
              cursor: pointer;
              position: relative;
              right: 16px;
            }
          }
        }
      }
    }

    .bigStar {
      display: inline-block;
      transition: all .15s ease-in-out;
      background-image: url("../assets/images/fav_new.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 66px;
      height: 12px;
      padding: 26px;
    }

    #send-list {
      color: #fff;
    }

    .benchmark-price {
      font-weight: bold;
      margin-top: 5px;
    }

    .unit-price {
      font-size: 10px;
    }

    .title {
      font-size: 14px;
      border-bottom-width: 1px;
      border-style: solid;
      padding-bottom: 12px;
    }

    .notice {
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      border-top-width: 1px;
      border-style: solid;
    }

    .recipient {
      width: 100%;
      text-align: center;
      padding-bottom: 12px;
    }

    .bookmarksWrap {
      padding-top: 14px;
      padding-bottom: 14px;

      .benchmark {
        display: flex;
        text-align: left;
        align-content: center;
        align-items: center;

        &:nth-child(n + 2) {
          padding-top: 12px;
        }

        > .benchmarkContent {
          display: flex;
          text-align: left;
          align-content: center;
          align-items: center;


          .imageBox {
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
              display: block;
              height: auto;
              max-width: 120px;
              max-height: 120px;
            }
          }

          .textBox {
            padding: 12px;
            line-height: 1.3;
            width: 100%;
            max-width: 250px;
            min-width: 250px;
          }
        }

        > .btnBox button {
          padding: 12px;
        }
      }
    }

    .empty {
      padding-top: 12px;
      font-size: 14px;
    }
  }

  button.close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999999999;
    display: inline-block;
    padding: 20px;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: 24px;
    transition: all .15s ease-in-out;
    background-image: url("../assets/images/ic_close_black_24px.svg");
    background-color: white;
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
}


.contact-info-tab {
  display: flex;
  gap: 60px;
}

.contact-info-tab h3 {
  margin-bottom: 10px;
  font-size: 15px;
}

@media (max-width: 768px) {
  .contact-info-tab {
    flex-direction: column;
  }
}

.trademark {
  margin-top: 0.5em;
}

.IdleModal {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  transform: translateY(-100%);
  transition: all .3s ease-in-out .3s;

  button.close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    display: inline-block;
    padding: 20px;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: 24px;
    transition: all .15s ease-in-out;
    background-image: url("../assets/images/ic_close_black_24px.svg");
    background-color: white;
  }

  .counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > * {
      text-align: center;
    }

    h2 {
      margin-bottom: 0.7rem;

      + p {
        margin-bottom: 0.5rem;

        + p {
          font-size: xxx-large;
        }
      }
    }


    button {
      margin: 0.9rem;
    }
  }

  &.open {
    transform: translateY(0);
  }
}

.screensaver {
  height: 100vh;
  width: 100vw;
  position: relative;

  video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    min-width: 100vw;
    min-height: 100vh;
  }
}

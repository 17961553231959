.page.searchProductsPage {

  position: relative;

  .sortMenu {
    position: fixed;
    max-width: 200px;
    top: 66px;
    z-index: 13;
    right: 0;
    display: inline-flex;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 12px 12px;
    background-color: white;
    flex-flow: row wrap;
    &.open{
      border: solid 1px;
    }

    a {
      width: 100%;
      max-width: 200px;
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 24px;
      min-height: 48px;

      &.active:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--primaryColor);
        background-image: url("../assets/images/ic_check_24px.svg");
        background-size: cover;
        height: 30px;
        width: 30px;
        border-radius: 100%;
      }

      + a {
        border-top: solid 1px;
        margin-top: 0.7rem;
      }
    }
  }

  .productGrid {
    display: flex;
    flex-flow: wrap;
    padding: 12px 12px;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .paging {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 1rem;

    button {
      padding: 1rem;
      min-height: 3rem;
      min-width: 3rem;
      margin: 0.3rem;
      opacity: .5;

      &.active {
        opacity: 1;
      }
    }
  }
}

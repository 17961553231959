nav.Header {
  position: fixed;
  width: calc(100vw - 64px);
  height: 66px;
  background-size: 43px auto;
  border: solid #d3d3d3;
  border-width: 1px 0;
  min-width: 340px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  z-index: 99;
  background-color: white;
  padding: 0 32px;

  button {
    background-color: transparent;
  }

  .button-group {
    display: flex;

    .back {
      padding-top: 0.1rem;
      padding-left: 2.8rem;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url("../assets/images/Chevronleft.svg");
    }

    &:nth-of-type(1) {
      a,
      button {
        position: relative;
        border: 0 solid #d3d3d3;
        width: 65px;
        height: 100%;
        background-size: 30px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &.i18n > span {
          background: url(../assets/images/language.svg) no-repeat center;
        }

        > span {
          display: inline-block;
          padding: 12px;
          background: url(../assets/images/arrow_left_black.svg) no-repeat center;
          background-size: 30px;
          transition: all .15s ease-in-out;
        }
      }
    }

    &:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      height: 66px;
      transform: translateX(-50%);

      .Logo {
        background-position: center;
      }

      @media (orientation: portrait) {
        & {
          left: 50px;
          transform: translateX(24px);
        }
        &.indent {
          transform: translateX(90px);
        }
        .Logo {
          background-position: left center;
        }
      }

      a {
        display: flex;
        align-content: center;

        > img {
          height: 40px;
          width: auto;
          margin: auto auto auto -50%;
        }
      }
    }

    &:nth-of-type(3) {
      display: flex;
      justify-content: flex-end;

      .vehicleOption {
        padding-left: 12px;
        display: inline-block;

        .seriesName:only-child {
          padding-right: 8px;
        }

        .modelName {
          margin: 0 0 0 8px;
          display: inline-block;
          font-size: 12px;
          line-height: 12.5px;
          text-align: left;
          padding-right: 8px;
          overflow: hidden;
        }
      }

      a,
      button {
        position: relative;
        border: 0 solid #d3d3d3;
        width: 65px;
        height: 100%;
        background-size: 30px auto;
        display: grid;

        > span {
          margin: auto;
        }

        &.cars {
          display: flex;
          justify-content: flex-start;

          &.selected {
            justify-content: flex-end;
            width: unset;
          }

          > span {
            &:first-child {
              display: inline-block;
              padding: 12px;
              background: url(../assets/images/car_new.svg) no-repeat 50%;
              background-size: 30px;
              transition: all .15s ease-in-out;
            }
          }
        }

        &.bookmarks {
          > span {
            &:first-child {
              display: inline-block;
              padding: 13px;
              background: url(../assets/images/fav_new.svg) no-repeat 50%;
              background-size: 30px;
              transition: all .15s ease-in-out;
            }

            &.count {
              position: absolute;
              display: block;
              top: 3px;
              right: 3px;
              width: auto;
              min-width: 12px;
              padding: 0 6px;
              border-radius: 12px;
              font-size: 11px;
              line-height: 24px;
              text-align: center;
              color: #fff;
            }
          }
        }

        &.search {
          > span {
            display: inline-block;
            background: url(../assets/images/search.svg) no-repeat 50%;
            transition: all .15s ease-in-out;
            padding: 13px;
            background-size: 30px;
          }
        }
      }
    }
  }
}

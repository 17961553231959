.TextBlock {
  margin: 12px 0;

  .content {
    padding: 6px 4px 0 0;
    line-height: 24px;
    font-size: 16px;
  }

  &.open,
  &.collapsed {
    .title {
      background-image: url(../assets/images/baseline-keyboard_arrow_right-24px.svg);
      background-position: right center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.open .title {
    background-image: url(../assets/images/baseline-keyboard_arrow_down-24px.svg);
  }
}

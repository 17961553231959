.ModelSelector {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: all .15s ease-in-out;
  backdrop-filter: blur(8px);
  cursor: pointer;
  z-index: 2;


  @media(orientation: landscape) {
    > div {
      width: 32%;
    }
  }

  @media(orientation: portrait) {
    > div {
      width: 64.00%;
    }
  }

  > div {
    cursor: default;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    height: 100.0%;
    box-shadow: 0 0 0 1px rgb(233, 233, 233);

    > div {
      position: absolute;
      z-index: 3;
      top: 0;
      right: 0;
      width: calc(100.0% - 20px);
      height: 100.0%;
      overflow: auto;

      > div {
        margin-top: 20px;
        width: calc(100.0% - 20px);
        background-color: white;
        border-radius: 10px;

        > a:first-child {
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          font-weight: 400;

          img {
            height: 72px;
            width: auto;
            padding-right: 8px;
          }
        }

        a {
          display: block;
          margin: 0 10px;
          padding: 12px 30px 12px 0;

          + a {
            font-size: 14px;
            border-top: solid 1px;
            position: relative;

            &:after {
              position: absolute;
              top: 0;
              right: 0;
              width: 30px;
              height: 100.0%;
              content: '';
              opacity: 0.30;
              background: url("../assets/images/ic_keyboard_arrow_right_black_24px.svg") no-repeat center right -6px;
              background-size: 24px;
            }
          }
        }
      }
    }

    &:before {
      top: calc(50.00% - 22px);
      left: -22px;
      width: 42px;
      height: 42px;
      transform: rotate(45deg);
      box-shadow: 0 0 0 1px rgb(233, 233, 233);
    }

    &:after {
      z-index: 2;
      top: 0;
      right: 0;
      width: 100.0%;
      height: 100.0%;
    }

    &:before, &:after {
      position: absolute;
      content: '';
      background-color: rgba(242, 242, 247, 1.00);
    }
  }

  &.open {
    transform: translateX(0);
  }
}

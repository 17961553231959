@import "../helper/queries";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

html {
  width: 100%;
  height: 100%;
  min-width: 580px;
  overflow-x: hidden;

  &.filterOpen {
    overflow: hidden;
  }
}

p {
  + p, + ul, + ol {
    margin-top: 0.7rem;
  }
}

ul, ol {
  padding-left: 24px;

  li {
    list-style-type: initial;

    + li {
      margin-top: 0.7rem;
    }
  }
}

button {
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  &.More {
    padding-right: 24px;
    background-color: transparent;
    background-image: url(../assets/images/baseline-keyboard_arrow_right-24px.svg);
    background-position: center right;
    background-repeat: no-repeat;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

body {
  width: 100%;
  height: 100%;
  min-width: 580px;
}

* {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}

img,
ul {
  display: block;
}

a {
  text-decoration: none;
  cursor: pointer;
}

body {
  width: 100%;
  height: 100%;
  line-height: 1;
}

table {
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
}

::-moz-placeholder {
  font-family: 'sofi-100', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #333;
}

::-webkit-input-placeholder {
  font-family: 'sofi-100', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #333;
}

:-ms-input-placeholder {
  font-family: 'sofi-100', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #333;
}

body {
  font-weight: 100;
  font-family: 'sofi-100', Arial, Helvetica, sans-serif;
}

.page {
  padding-top: calc(68px + 35px);
}

#colorPicker {
  display: none;
  background-color: var(--primaryColor);
}

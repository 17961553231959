.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;

  .product {
    position: relative;
    flex: 0 0 220px;
  }
}
